import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@/Components/Icon/Icon';
import { KeyboardKey } from '@standardnotes/ui-services';
import { $getSelection, $isRangeSelection, $isTextNode } from 'lexical';
import { useCallback, useEffect, useRef, useState } from 'react';
import { VisuallyHidden } from '@ariakit/react';
import { getSelectedNode } from '../../Lexical/Utils/getSelectedNode';
import { $isLinkNode } from '@lexical/link';
import StyledTooltip from '@/Components/StyledTooltip/StyledTooltip';
export const $isLinkTextNode = (node, selection) => {
    const parent = node.getParent();
    return $isLinkNode(parent) && $isTextNode(node) && selection.anchor.getNode() === selection.focus.getNode();
};
const LinkTextEditor = ({ linkText, editor, isEditMode, setEditMode }) => {
    const [editedLinkText, setEditedLinkText] = useState(() => linkText);
    const editModeContainer = useRef(null);
    useEffect(() => {
        setEditedLinkText(linkText);
    }, [linkText]);
    const focusInput = useCallback((input) => {
        if (input) {
            input.focus();
        }
    }, []);
    const handleLinkTextSubmission = () => {
        editor.update(() => {
            const selection = $getSelection();
            if (!$isRangeSelection(selection)) {
                return;
            }
            const node = getSelectedNode(selection);
            if (!$isLinkTextNode(node, selection)) {
                return;
            }
            node.setTextContent(editedLinkText);
        });
        setEditMode(false);
    };
    return isEditMode ? (_jsxs("div", { className: "flex items-center gap-2", ref: editModeContainer, children: [_jsx("input", { id: "link-input", ref: focusInput, value: editedLinkText, onChange: (event) => {
                    setEditedLinkText(event.target.value);
                }, onKeyDown: (event) => {
                    if (event.key === KeyboardKey.Enter) {
                        event.preventDefault();
                        handleLinkTextSubmission();
                    }
                    else if (event.key === KeyboardKey.Escape) {
                        event.preventDefault();
                        setEditMode(false);
                    }
                }, onBlur: (event) => {
                    var _a;
                    if (!((_a = editModeContainer.current) === null || _a === void 0 ? void 0 : _a.contains(event.relatedTarget))) {
                        setEditMode(false);
                    }
                }, className: "flex-grow rounded-sm bg-contrast p-1 text-text sm:min-w-[20ch]" }), _jsx(StyledTooltip, { showOnMobile: true, showOnHover: true, label: "Cancel editing", children: _jsx("button", { className: "flex select-none items-center justify-center rounded p-2 enabled:hover:bg-default disabled:opacity-50 md:border md:border-transparent enabled:hover:md:translucent-ui:border-[--popover-border-color]", onClick: () => {
                        setEditMode(false);
                        editor.focus();
                    }, onMouseDown: (event) => event.preventDefault(), children: _jsx(Icon, { type: "close", size: "medium" }) }) }), _jsx(StyledTooltip, { showOnMobile: true, showOnHover: true, label: "Save link text", children: _jsx("button", { className: "flex select-none items-center justify-center rounded p-2 enabled:hover:bg-default disabled:opacity-50 md:border md:border-transparent enabled:hover:md:translucent-ui:border-[--popover-border-color]", onClick: handleLinkTextSubmission, onMouseDown: (event) => event.preventDefault(), children: _jsx(Icon, { type: "check", size: "medium" }) }) })] })) : (_jsxs("div", { className: "flex items-center gap-1", children: [_jsx(Icon, { type: "plain-text", className: "ml-1 mr-1 flex-shrink-0" }), _jsxs("div", { className: "flex-grow overflow-hidden text-ellipsis whitespace-nowrap", children: [_jsx(VisuallyHidden, { children: "Link text:" }), linkText] }), _jsx(StyledTooltip, { showOnMobile: true, showOnHover: true, label: "Edit link text", children: _jsx("button", { className: "flex select-none items-center justify-center rounded p-2 enabled:hover:bg-default disabled:opacity-50 md:border md:border-transparent enabled:hover:md:translucent-ui:border-[--popover-border-color]", onClick: () => {
                        setEditedLinkText(linkText);
                        setEditMode(true);
                    }, onMouseDown: (event) => event.preventDefault(), children: _jsx(Icon, { type: "pencil-filled", size: "medium" }) }) })] }));
};
export default LinkTextEditor;
