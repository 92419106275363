import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { getPositionedPopoverStyles } from '@/Components/Popover/GetPositionedPopoverStyles';
import { $getSelection, $isRangeSelection, COMMAND_PRIORITY_LOW, SELECTION_CHANGE_COMMAND, } from 'lexical';
import { useCallback, useEffect, useRef, useState } from 'react';
import { getDOMRangeRect } from '../../Lexical/Utils/getDOMRangeRect';
import { classNames } from '@standardnotes/snjs';
import Icon from '@/Components/Icon/Icon';
import StyledTooltip from '@/Components/StyledTooltip/StyledTooltip';
import { TOGGLE_LINK_COMMAND } from '@lexical/link';
import { mergeRegister } from '@lexical/utils';
import { KeyboardKey } from '@standardnotes/ui-services';
import Button from '@/Components/Button/Button';
import { sanitizeUrl } from '../../Lexical/Utils/sanitizeUrl';
import { getSelectedNode } from '../../Lexical/Utils/getSelectedNode';
import { $isLinkTextNode } from './ToolbarLinkTextEditor';
import { useElementResize } from '@/Hooks/useElementRect';
import { createPortal } from 'react-dom';
import { ElementIds } from '@/Constants/ElementIDs';
import { getAdjustedStylesForNonPortalPopover } from '@/Components/Popover/Utils/getAdjustedStylesForNonPortal';
const FloatingLinkEditor = ({ linkUrl, linkText, isEditMode, setEditMode, editor, isAutoLink, isLinkText, isMobile, }) => {
    var _a;
    const [editedLinkUrl, setEditedLinkUrl] = useState(() => linkUrl);
    useEffect(() => {
        setEditedLinkUrl(linkUrl);
    }, [linkUrl]);
    const [editedLinkText, setEditedLinkText] = useState(() => linkText);
    useEffect(() => {
        setEditedLinkText(linkText);
    }, [linkText]);
    const linkEditorRef = useRef(null);
    const rangeRect = useRef();
    const updateLinkEditorPosition = useCallback(() => {
        if (isMobile) {
            return;
        }
        const nativeSelection = window.getSelection();
        const rootElement = editor.getRootElement();
        if (nativeSelection !== null && rootElement !== null) {
            if (rootElement.contains(nativeSelection.anchorNode)) {
                rangeRect.current = getDOMRangeRect(nativeSelection, rootElement);
            }
        }
        const linkEditorElement = linkEditorRef.current;
        if (!linkEditorElement) {
            setTimeout(updateLinkEditorPosition);
            return;
        }
        if (!rootElement) {
            return;
        }
        const linkEditorRect = linkEditorElement.getBoundingClientRect();
        const rootElementRect = rootElement.getBoundingClientRect();
        const calculatedStyles = getPositionedPopoverStyles({
            align: 'center',
            side: 'top',
            anchorRect: rangeRect.current,
            popoverRect: linkEditorRect,
            documentRect: rootElementRect,
            offset: 12,
            maxHeightFunction: () => 'none',
        });
        if (calculatedStyles) {
            const adjustedStyles = getAdjustedStylesForNonPortalPopover(linkEditorElement, calculatedStyles);
            Object.entries(adjustedStyles).forEach(([key, value]) => {
                linkEditorElement.style.setProperty(key, value);
            });
            linkEditorElement.style.opacity = '1';
        }
    }, [editor, isMobile]);
    useElementResize(linkEditorRef.current, updateLinkEditorPosition);
    useEffect(() => {
        updateLinkEditorPosition();
        return mergeRegister(editor.registerUpdateListener(() => {
            updateLinkEditorPosition();
        }), editor.registerCommand(SELECTION_CHANGE_COMMAND, (_payload) => {
            updateLinkEditorPosition();
            return false;
        }, COMMAND_PRIORITY_LOW));
    }, [editor, updateLinkEditorPosition]);
    const focusInput = useCallback((input) => {
        if (input) {
            input.focus();
        }
    }, []);
    const handleSubmission = () => {
        if (editedLinkUrl !== '') {
            editor.dispatchCommand(TOGGLE_LINK_COMMAND, sanitizeUrl(editedLinkUrl));
        }
        editor.update(() => {
            const selection = $getSelection();
            if (!$isRangeSelection(selection)) {
                return;
            }
            const node = getSelectedNode(selection);
            if (!$isLinkTextNode(node, selection)) {
                return;
            }
            node.setTextContent(editedLinkText);
        });
        setEditMode(false);
    };
    useEffect(() => {
        setTimeout(updateLinkEditorPosition);
    }, [isEditMode, updateLinkEditorPosition]);
    return createPortal(_jsx("div", { id: "super-link-editor", className: "absolute bottom-12 left-1/2 z-modal w-[calc(100%_-_1rem)] -translate-x-1/2 rounded-lg border border-border bg-contrast px-2 py-1 shadow-sm shadow-contrast translucent-ui:border-[--popover-border-color] translucent-ui:bg-[--popover-background-color] translucent-ui:[backdrop-filter:var(--popover-backdrop-filter)] md:bottom-[unset] md:left-0 md:top-0 md:w-auto md:translate-x-0 md:opacity-0", ref: linkEditorRef, children: isEditMode ? (_jsxs("div", { className: "flex flex-col gap-2 py-1", onBlur: (event) => {
                var _a;
                if (!((_a = linkEditorRef.current) === null || _a === void 0 ? void 0 : _a.contains(event.relatedTarget))) {
                    setEditMode(false);
                }
            }, children: [isLinkText && (_jsxs("div", { className: "flex items-center gap-1.5", children: [_jsx(Icon, { type: "plain-text", className: "flex-shrink-0" }), _jsx("input", { value: editedLinkText, onChange: (event) => {
                                setEditedLinkText(event.target.value);
                            }, onKeyDown: (event) => {
                                if (event.key === KeyboardKey.Enter) {
                                    event.preventDefault();
                                    handleSubmission();
                                }
                                else if (event.key === KeyboardKey.Escape) {
                                    event.preventDefault();
                                    setEditMode(false);
                                }
                            }, className: "flex-grow rounded-sm bg-contrast p-1 text-text sm:min-w-[20ch]" })] })), _jsxs("div", { className: "flex items-center gap-1.5", children: [_jsx(Icon, { type: "link", className: "flex-shrink-0" }), _jsx("input", { ref: focusInput, value: editedLinkUrl, onChange: (event) => {
                                setEditedLinkUrl(event.target.value);
                            }, onKeyDown: (event) => {
                                if (event.key === KeyboardKey.Enter) {
                                    event.preventDefault();
                                    handleSubmission();
                                }
                                else if (event.key === KeyboardKey.Escape) {
                                    event.preventDefault();
                                    setEditMode(false);
                                }
                            }, className: "flex-grow rounded-sm bg-contrast p-1 text-text sm:min-w-[40ch]" })] }), _jsxs("div", { className: "flex items-center justify-end gap-1.5", children: [_jsx(StyledTooltip, { showOnMobile: true, showOnHover: true, label: "Cancel editing", children: _jsx(Button, { onClick: () => {
                                    setEditMode(false);
                                    editor.focus();
                                }, onMouseDown: (event) => event.preventDefault(), children: "Cancel" }) }), _jsx(StyledTooltip, { showOnMobile: true, showOnHover: true, label: "Save link", children: _jsx(Button, { primary: true, onClick: handleSubmission, onMouseDown: (event) => event.preventDefault(), children: "Apply" }) })] })] })) : (_jsxs("div", { className: "flex items-center gap-1", children: [_jsxs("a", { className: classNames('mr-1 flex flex-grow items-center gap-2 overflow-hidden whitespace-nowrap underline', isAutoLink && 'py-2.5'), href: linkUrl, target: "_blank", rel: "noopener noreferrer", children: [_jsx(Icon, { type: "open-in", className: "ml-1 flex-shrink-0" }), _jsx("div", { className: "max-w-[35ch] overflow-hidden text-ellipsis", children: linkUrl })] }), _jsx(StyledTooltip, { showOnMobile: true, showOnHover: true, label: "Copy link", children: _jsx("button", { className: "flex select-none items-center justify-center rounded p-2 enabled:hover:bg-default disabled:opacity-50 md:border md:border-transparent enabled:hover:md:translucent-ui:border-[--popover-border-color]", onClick: () => {
                            navigator.clipboard.writeText(linkUrl).catch(console.error);
                        }, onMouseDown: (event) => event.preventDefault(), children: _jsx(Icon, { type: "copy", size: "medium" }) }) }), !isAutoLink && (_jsxs(_Fragment, { children: [_jsx(StyledTooltip, { showOnMobile: true, showOnHover: true, label: "Edit link", children: _jsx("button", { className: "flex select-none items-center justify-center rounded p-2 enabled:hover:bg-default disabled:opacity-50 md:border md:border-transparent enabled:hover:md:translucent-ui:border-[--popover-border-color]", onClick: () => {
                                    setEditedLinkUrl(linkUrl);
                                    setEditMode(true);
                                }, onMouseDown: (event) => event.preventDefault(), children: _jsx(Icon, { type: "pencil-filled", size: "medium" }) }) }), _jsx(StyledTooltip, { showOnMobile: true, showOnHover: true, label: "Remove link", children: _jsx("button", { className: "flex select-none items-center justify-center rounded p-2 enabled:hover:bg-default disabled:opacity-50 md:border md:border-transparent enabled:hover:md:translucent-ui:border-[--popover-border-color]", onClick: () => {
                                    editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
                                }, onMouseDown: (event) => event.preventDefault(), children: _jsx(Icon, { type: "trash-filled", size: "medium" }) }) })] }))] })) }), (_a = document.getElementById(ElementIds.SuperEditor)) !== null && _a !== void 0 ? _a : document.body);
};
export default FloatingLinkEditor;
